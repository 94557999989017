.featured_card_container {
  min-width: 282px;
  background-color: #24262b;
  border-radius: 18px;
  padding-block: 17px;
  padding-inline: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.featured_card_container .content_container {
  display: flex;
  gap: 13px;
}

.color_line {
  flex-shrink: 0;
  width: 4px;
  height: auto;
  border-radius: 100px;
  /* border-left: 4px solid #AC85FF; */
}

.border-red {
  background-color: #d8213a;
}

.border-yellow {
  background-color: #fedd71;
}

.border-green {
  background-color: #59d456;
}

.border-purple {
  background-color: #d14ce7;
}

.featured_details_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.featured_details {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.featured_details h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.045em;
  color: #ffffff;
}

.featured_details p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #8f97a6;
}

.card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_footer .footer_button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13.2209px;
  line-height: 18px;
  text-align: center;
  --color: #ffffff;
  text-transform: capitalize;
  margin-left: 12px;
}

.featured_icons {
  display: flex;
  align-items: center;
  gap: 16px;
}